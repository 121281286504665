import  React, { useState } from "react"
import axios from 'axios';

//COMPONENTS
import Layout from "@components/layout"
import Seo from "@components/Seo.js"
import Breadcrumbs from "@components/Breadcrumbs"

//IMAGES
// import Start from "../../images/free-trial/start.svg"
// import User from "../../images/free-trial/user.svg"
// import Balance from "../../images/free-trial/balance.svg"
// import CheckOrder from "../../images/free-trial/check-order.svg"
// import LoadingOrder from "../../images/free-trial/loading-order.svg"
// import FinishOrder from "../../images/free-trial/finish-order.svg"
// import Tell from "../../images/free-trial/tell.svg"

const FreeTrialPage = () => {
    const steps = [
        {
            name: 'Free trial',
            path: '/free-trial'
        }
    ]

    const [btnIsDisebled, setBtnIsDisebled] = useState(false)

    // const cards = [
    //     {
    //         icon: Start,
    //         alt: 'start free',
    //         text: {
    //             __html: 'Start <span>free trial</span>'
    //         }
    //     },
    //     {
    //         icon: User,
    //         alt: 'register an account',
    //         text: {
    //             __html: '<span>Register</span> in just 30 seconds'
    //         }
    //     },
    //     {
    //         icon: Balance,
    //         alt: 'get 25$',
    //         text: {
    //             __html: 'Get <span>$25.00</span> US dollars to spend.'
    //         }
    //     },
    //     {
    //         icon: CheckOrder,
    //         alt: 'you first order',
    //         text: {
    //             __html: 'Place your <span>first order</span> for free'
    //         }
    //     },
    //     {
    //         icon: LoadingOrder,
    //         alt: 'log in',
    //         text: {
    //             __html: 'Stay in contact with <span>your writer</span> and the support team'
    //         }
    //     },
    //     {
    //         icon: FinishOrder,
    //         alt: 'get your paper',
    //         text: {
    //             __html: 'Receive the <span>finished paper</span>'
    //         }
    //     },
    //     {
    //         icon: Tell,
    //         alt: 'tell you friends',
    //         text: {
    //             __html: 'Tell your friends about our <span>amazing service</span> and come back for more!'
    //         }
    //     }
    // ]

    const redirect = async () => {
        try {
            setBtnIsDisebled(true)
            const { data } = await axios.post('https://shop.genuinewriting.com/api/free-trial/create-hash', { type_promotion: 'string' })
            window.location.href = `https://shop.genuinewriting.com/register?hash=${data}`
        } catch (error) {
            console.error(error)
        }
        finally {
            setBtnIsDisebled(false)
        }        
    }

    return (
        <Layout>
            <Seo title="Best Essay Writers Online | genuinewriting.com"></Seo>
            <div className="page-trial-intro">
                <div className="wrapper">
                    <div className="page-trial-intro--content">
                        <Breadcrumbs steps={steps}/>
                        <h1 className="page-intro__title">Testdrive our service</h1>
                        <p className="page-trial-intro--subtitle">
                            We are very confident that you will like our service! 
                            Therefore, we give every new client a unique opportunity to place the first order absolutely free of charge to assess the quality of our service. 
                            Get started right away!
                        </p>
                        <button
                            className="button button-primary"
                            disabled={btnIsDisebled}
                            onClick={redirect}
                        >
                            Order free of charge
                        </button>
                    </div>                    
                </div>
            </div>
            <div className="page-trial__content wrapper">
                <h2 className="page-trial__content-title">
                    Here's how the <span className="font-colored">free trial</span> works
                </h2>
                {/* <div className="page-trial__content-cards">
                    {cards.map((item, index) => 
                        <div
                            key={index}
                            className="page-trial__content-card"
                        >
                            <div className="page-trial__content-icon">
                                <img src={item.icon} alt={item.alt} />
                            </div>
                            <span className="page-trial__content-counter">{ index + 1 }</span>
                            <span className="page-trial__content-desc" dangerouslySetInnerHTML={item.text}></span>
                        </div>
                    )}
                    
                </div> */}
                <div className="page-trial__content-button">
                    <button
                        className="button button-primary page-trial__content-btn"
                        disabled={btnIsDisebled}
                        onClick={redirect}
                    >
                        start now
                    </button>
                </div>
            </div>
        </Layout>
    )
}

export default FreeTrialPage